import { Box } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';

import { ButtonCore } from '~/components/core-components';

import styles from './story-content.module.css';

export const Colleagues1 = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return <Box backgroundImage={isMobile ? '/colleagues_mobile_1.png' : '/colleagues_1.png'} className={styles['stories']} />;
};

export const Colleagues2 = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return <Box backgroundImage={isMobile ? '/colleagues_mobile_2.png' : '/colleagues_2.png'} className={styles['stories']} />;
};

export const Colleagues3 = () => {
    const router = useRouter();
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return (
        <Box backgroundImage={isMobile ? '/colleagues_mobile_3.png' : '/colleagues_3.png'} className={styles['stories']}>
            <ButtonCore
                size='large'
                view='accent'
                onClick={() => {
                    router.push('/catalog/all');
                }}
                className={styles['button-story']}
            >
                Посмотреть все напитки
            </ButtonCore>
        </Box>
    );
};

export const Friends1 = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return <Box backgroundImage={isMobile ? '/friends_mobile_1.png' : '/friends_1.png'} className={styles['stories']} />;
};

export const Friends2 = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return <Box backgroundImage={isMobile ? '/friends_mobile_2.png' : '/friends_2.png'} className={styles['stories']} />;
};

export const Friends3 = () => {
    const router = useRouter();
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return (
        <Box backgroundImage={isMobile ? '/friends_mobile_3.png' : '/friends_3.png'} className={styles['stories']}>
            <ButtonCore
                size='large'
                view='accent'
                onClick={() => {
                    router.push('/catalog/all');
                }}
                className={styles['button-story']}
            >
                Перейти в каталог
            </ButtonCore>
        </Box>
    );
};

export const Children1 = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return <Box backgroundImage={isMobile ? '/children_mobile_1.png' : '/children_1.png'} className={styles['stories']} />;
};

export const Children2 = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return <Box backgroundImage={isMobile ? '/children_mobile_2.png' : '/children_2.png'} className={styles['stories']} />;
};

export const Children3 = () => {
    const router = useRouter();
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return (
        <Box backgroundImage={isMobile ? '/children_mobile_3.png' : '/children_3.png'} className={styles['stories']}>
            <ButtonCore
                size='large'
                view='accent'
                onClick={() => {
                    router.push('/catalog/all');
                }}
                className={`${styles['button-story']} ${styles['white-button']}`}
            >
                Посмотреть заведения
            </ButtonCore>
        </Box>
    );
};

export const Lover1 = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return <Box backgroundImage={isMobile ? '/lover_mobile_1.png' : '/lover_1.png'} className={styles['stories']} />;
};

export const Lover2 = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return <Box backgroundImage={isMobile ? '/lover_mobile_2.png' : '/lover_2.png'} className={styles['stories']} />;
};

export const Lover3 = () => {
    const router = useRouter();
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return (
        <Box backgroundImage={isMobile ? '/lover_mobile_3.png' : '/lover_3.png'} className={styles['stories']}>
            <ButtonCore
                size='large'
                view='accent'
                onClick={() => {
                    router.push('/catalog/all');
                }}
                className={`${styles['button-story']} ${styles['white-button']}`}
            >
                Перейти в каталог
            </ButtonCore>
        </Box>
    );
};
